.imageCard {
	box-shadow: var(--box-shadow-s);
	border-radius: 4px;
	padding: 0.5rem;
	width: -moz-fit-content;
	width: fit-content;
}

.header {
	display: flex;
	max-width: 20rem;
	justify-content: space-between;
	padding-bottom: 10px;
}

.headerButtons {
	display: flex;
	grid-gap: 1rem
}

.imageContainer {
	display: grid;
	place-items: center;
	max-width: 200px !important;
}

.imagePlaceholder {
	font-size: 96px;
	color: #DDD;
	padding: 1rem;
}

.row {
	display: grid;
	grid-template-columns: 0.25fr 0.25fr 1.75fr 2fr 5fr 1fr;
	place-items: center;
	align-items: center;
	gap: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 1rem;
	-webkit-user-select: none;
	user-select: none;
}

.uploadIcon {
	font-size: 32px;
	place-self: center;
}

.actions {
	display: flex;
	gap: 1rem;
}
