.imageCard {
	box-shadow: var(--box-shadow-s);
	border-radius: 4px;
	padding: 0.5rem;
	width: -moz-fit-content;
	width: fit-content;
}

.header {
	display: flex;
	max-width: 20rem;
	justify-content: end;
	padding-bottom: 10px;
}

.headerButtons {
	display: flex;
	grid-gap: 1rem
}

.imageDelete {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 5px solid #E50E62;
	z-index: 15;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	cursor: not-allowed;
	background: rgba(255, 255, 255, 0.6);
	overflow: hidden;
}
.imageDeleteDiagonal {
	background: #E50E62;
  width: 100%;
  height: 10px;
	transform: skewY(-52deg);
}

.imageContainer {
	display: grid;
	place-items: center;
	max-width: 100%;
	position: relative;
	margin: auto auto;
	margin-bottom: 1rem;
	border: 1px solid #aaa;
	width: 100%;
	height: 100%;
}

.imagePlaceholder {
	font-size: 96px;
	color: #DDD;
	padding: 1rem;
}

.row {
	display: grid;
	grid-template-columns: 0.25fr 0.25fr 1.75fr 2fr 5fr 1fr;
	place-items: center;
	align-items: center;
	gap: 1rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 1rem;
	-webkit-user-select: none;
	user-select: none;
}

.uploadIcon {
	font-size: 32px;
	place-self: center;
}

.actions {
	display: flex;
	gap: 1rem;
}
