.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tglbtn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tglbtn::selection {
  background: none;
}
.tgl + .tglbtn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.tgl + .tglbtn:after, .tgl + .tglbtn:before {
  position: relative;
  display: block;
  content: "";
 ;
  width: 50%;
  height: 100%;
}
.tgl + .tglbtn:after {
  left: 0;
}
.tgl + .tglbtn:before {
  display: none;
}
.tgl:checked + .tglbtn:after {
  left: 50%;
}
.tgllight + .tglbtn {
  background: #f0f0f0;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.tgllight + .tglbtn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.tgllight:checked + .tglbtn {
  background: #9fd6ae;
}
.tglios + .tglbtn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}
.tglios + .tglbtn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 4px 0 rgba(0, 0, 0, .08);
}
.tglios + .tglbtn:hover:after {
  will-change: padding;
}
.tglios + .tglbtn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tglios + .tglbtn:active:after {
  padding-right: 0.8em;
}
.tglios:checked + .tglbtn {
  background: #86d993;
}
.tglios:checked + .tglbtn:active {
  box-shadow: none;
}
.tglios:checked + .tglbtn:active:after {
  margin-left: -0.8em;
}
.tglskewed + .tglbtn {
  overflow: hidden;
  transform: skew(-10deg);
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: #888;
}
.tglskewed + .tglbtn:after, .tglskewed + .tglbtn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
}
.tglskewed + .tglbtn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.tglskewed + .tglbtn:before {
  left: 0;
  content: attr(data-tg-off);
}
.tglskewed + .tglbtn:active {
  background: #888;
}
.tglskewed + .tglbtn:active:before {
  left: -10%;
}
.tglskewed:checked + .tglbtn {
  background: #86d993;
}
.tglskewed:checked + .tglbtn:before {
  left: -100%;
}
.tglskewed:checked + .tglbtn:after {
  left: 0;
}
.tglskewed:checked + .tglbtn:active:after {
  left: 10%;
}
.tglflat + .tglbtn {
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid #b2b2b2;
  border-radius: 2em;
  height: var(--height);
  width: var(--width);
  border-width: 2px;
  margin-top: -2px;
  margin-bottom: -2px;
}
.tglflat + .tglbtn:after {
  transition: all 0.2s ease;
  background: #b2b2b2;
  content: "";
  border-radius: 12em;
}
.tglflat:checked + .tglbtn {
  border: 4px solid var(--active-color);
  border-width: 2px;
}
.tglflat:checked + .tglbtn:after {
  left: 50%;
  background: var(--active-color);
}
.tglflip + .tglbtn {
  padding: 2px;
  transition: all 0.2s ease;
  font-family: sans-serif;
  perspective: 100px;
}
.tglflip + .tglbtn:after, .tglflip + .tglbtn:before {
  display: inline-block;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 4px;
}
.tglflip + .tglbtn:after {
  content: attr(data-tg-on);
  background: #02c66f;
  transform: rotateY(-180deg);
}
.tglflip + .tglbtn:before {
  background: #ff3a19;
  content: attr(data-tg-off);
}
.tglflip + .tglbtn:active:before {
  transform: rotateY(-20deg);
}
.tglflip:checked + .tglbtn:before {
  transform: rotateY(180deg);
}
.tglflip:checked + .tglbtn:after {
  transform: rotateY(0);
  left: 0;
  background: #7fc6a6;
}
.tglflip:checked + .tglbtn:active:after {
  transform: rotateY(20deg);
}