.busySpinnerContainer {
	display: grid;
	isolation: isolate;
	place-items: center;
	z-index: 100;
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: rgba(0,0,0,0.1);
}

.busySpinner {
	display: grid;
	place-items: center;
	padding: 2rem;
}
