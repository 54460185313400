.container {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--button-text-s);
    padding: 0.5rem;
    display: grid;
    grid-template-rows: auto 256px auto auto;
    width: 320px;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.buttons {
    display: flex;
    gap: 1rem;
}
