.busySpinnerContainer {
  display: grid;
  place-items: center;
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.1);
}

.busySpinnerContainerNonBlocking {
  display: grid;
  place-items: center;
  z-index: 10;
  position: fixed;
  width: calc(100vw - var(--nav));
  height: calc(100vh - var(--nav));
  left: var(--nav);
  top: var(--nav);
  background: rgba(0,0,0,0.1);
}

.busySpinner {
  display: grid;
  place-items: center;
  padding: 2rem;
}

.xtdLabel {
  border-radius: 1.1rem;
  border: 2px solid var(--odo-color-palette-magenta-light);
  color: var(--odo-color-palette-magenta-light);
  line-height: 1.2em;
  font-size: 1.9rem;
  font-weight: 700;
  padding: 1rem 2.5rem;
}