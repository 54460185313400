.react-draft-wrapper {
	border: 1px solid #aaa;
}

.react-draft-editor {
	padding: 2rem !important;
	margin: 0 !important;
	max-height: 600px;
	scroll-behavior: smooth;
}

.rdw-link-decorator-icon {
	display: none;
}

.rdw-link-modal {
	background-color: var(--background-secondary) !important;
	border-radius: var(--border-radius-l);
	box-shadow: var(--box-shadow-m);
	padding: 1.2rem;
	height: fit-content !important;
}

.rdw-link-modal input {
	padding: 1.2rem;
	border: 1px solid var(--component-primary);
	font-size: 1.4rem;
}

.rdw-link-modal button{
	padding: var(--button-padding-s);
	border-radius: var(--button-radius);
	border: 1px solid var(--component-primary);
	text-align: center;
}

.rdw-link-modal button:nth-child(1) {
	background-color: var(--brand-primary);
	color: white;
	font-weight: bold;
}

.rdw-link-modal-buttonsection {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.rdw-link-modal-target-option {
	display: none;
}
